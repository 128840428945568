/* font-family: 'Merienda', cursive; */

// $rich form black: #080708;
// $champagne-pink: #f9dec9;
// $wild-blue-yonder: #99b2dd;
// $purple-navy: #5e548e;
// $paradise pink: #EF476F;


//variables
$myblack: rgba(8, 7, 8, 1);
$mypurple: rgba(94, 84, 142, 1);
$myblue: rgba(153, 178, 221, 1);
$mypink: rgba(249, 222, 201, 1);
$myred: rgba(238, 71, 111, 1);

#skills {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
}

.skillsouterdivclass {
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    font-weight: bold;
    flex-direction: row;
    color: $mypurple;
    width: 90%;
    height: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .4);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
    box-shadow: rgba(0, 0, 0, 0.01) 0px 19px 38px, rgba(0, 0, 0, 0.05) 0px 15px 12px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-attachment: fixed;

    a {
        color: rgba(255, 255, 255, .9);
    }
}
.skillsinnerdivclass {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.skillsvid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 40%;
    margin-top: 1rem;
    height: 21rem;
    order: 0;
    min-height: 21rem;
    z-index: -1;
}
.titleparaskills {
    width: 100%;
    order: 1;
}

.skillstitleclass {
    font-size: 2.6rem;
    margin: 1rem;
    padding: 1rem;
    order: 0;
}

.skillsparadescriptionclass {
    font-size: 1.3rem;
    padding: 1rem;
    margin: 1rem;
    height: fit-content;
    width: fit-content;
    order: 1;

}
#codeacademy {
border-style: none !important;
}
#hackerrank {
    border-style: none !important;
}
.skillsmappingdivcontainerclass {
    font-size: 1.3rem;
    display: flex;
    padding-bottom: 5rem;
    height: fit-content;
    width: 100%;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: center;    
}


@media screen and (max-width: 1000px) {
    .skillsmappingdivcontainerclass {
    flex-direction: column;
    flex-flow: wrap;
    align-items: center;
    }
    .skillsinnerdivclass {
    text-align: center;
    }
    .skillsparadescriptionclass {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .skillsouterdivclass {
        width: 100%;
        margin: none;
        justify-self: center;
    }

    .skillsvid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 0;
        visibility: hidden;
        height: 0;
        order: 0;
    }

}
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
        .skillsmappingdivcontainerclass {
            flex-direction: column;
             flex-flow: wrap;
        }
        .skillsparadescriptionclass {
            display: flex;
            flex-flow: wrap;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
        }
        .skillsouterdivclass {
            width: 100%;
            margin: none;
            justify-self: center;
            font-size: 1rem;
        }
        .skillsvid {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 0;
            visibility: hidden;
            height: 0;
            order: 0;
        }
}

@media only screen 
  and (max-device-width: 320px) {
    .skillsmappingdivcontainerclass {
        flex-direction: column;
         flex-flow: wrap;
         font-size: .8rem;
    }
    .skillstitleclass {
        font-size: 2rem;
        margin: 1rem;
        padding: 1rem;
    }
    .skillsparadescriptionclass {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        font-size: .7rem;
    }
    .skillsouterdivclass {
        width: 100%;
        margin: none;
        justify-self: center;
        font-size: 1rem;
    }
    .skillsvid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 0;
        visibility: hidden;
        height: 0;
        order: 0;
    }
  }


.skillsmappinginnerspanclass {
    padding: 1rem;
    height: auto;
    width: auto;
}


