// $rich form black: #080708;
// $champagne-pink: #f9dec9;
// $wild-blue-yonder: #99b2dd;
// $purple-navy: #5e548e;
// $paradise pink: #EF476F;
// $rose white: rgba(255, 248, 248, 1); 



//variables
$myblack: rgba(8, 7, 8, 1);
$mypurple: rgba(94, 84, 142, 1);
$myblue: rgba(153, 178, 221, 1);
$mypink: rgba(249, 222, 201, 1);
$myred: rgba(238, 71, 111, 1);
$mywhite: rgba(255, 248, 248, 1); 



::-webkit-scrollbar {
  width: 1.5em;
  cursor: pointer;
  height: 1em;
}

::-webkit-scrollbar-track {
  background: rgba(122,175,221, .5);
  border: .1em solid $mypurple;

}

::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, .4);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px); 
  outline: .1em solid $mypurple;
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb:hover {
  background: $mypurple;
}

@supports (scrollbar-color: $myblue $mypurple) {
  * {
    scrollbar-color: $myblue $mypurple;
    scrollbar-width: auto;
  }
}
#appbodyid {
  padding: 0;
}


.containerclass {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  backface-visibility: visible;
  background-origin: border-box !important;
  background-attachment: fixed !important;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./assests/blueprint.webp');   
}
.line-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
}
svg {
  display: inline-block;
  height: 100%;
  
}
.appbodyclass {
  text-align: center;
  backface-visibility: visible;
  background-origin: border-box !important;
  background-attachment: fixed !important;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-image: url('./assests/blueprint.webp');
  overflow: hidden;
  width: 100%;
  margin: 3%;
  padding: 6%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

#navbardivid {
  z-index: 2;
  position: sticky;
  top: .1em;
  width: 100%;
}





