/* font-family: 'Merienda', cursive; */

// $rich form black: #080708;
// $champagne-pink: #f9dec9;
// $wild-blue-yonder: #99b2dd;
// $purple-navy: #5e548e;
// $paradise pink: #EF476F;
// $rose white: rgba(255, 248, 248, 1) 

//some other variables throughout the code defined above
//the parts as needed for easier toggling / changing of 
//effects
 *, *::before, *::after { 
     box-sizing: border-box 
 }
//my variables
:root {
    --typewriterSpeed: 6s;
    --typewriterCharacters: 11;
  }
  $myblack: rgba(8, 7, 8, 1);
  $mypurple: rgba(94, 84, 142, 1);
  $myblue: rgba(153, 178, 221, 1);
  $mypink: rgba(255, 255, 255, .9);
  $myred: rgba(238, 71, 111, 1);
  $mywhite: rgba(255, 248, 248, 1); 


  //change to adjust the curvature of the images
  $imageborderradius: 0%;

  $gradientbackground: linear-gradient(to bottom, rgba(94, 84, 142, .8), rgba(103, 101, 158, .8),
  rgba(112, 119, 173, .8), rgba(123, 136, 188, .8), rgba(135, 154, 202, .8), 
  rgba(141, 162, 208, .8), rgba(147, 170, 215, .8), rgba(153, 178, 221, .8),
   rgba(153, 178, 221, .8), rgba(153, 178, 221, .8), rgba(153, 178, 221, .8),
    rgba(153, 178, 221, .8));


#aboutcontainerid {

    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    color: $mypurple;
    font-family: 'Merienda', cursive;
}

#aboutinnercontainerid {
    order: 0 !important;
    width: auto;
    height: auto;
    position: relative;
    font-weight: 600;
    font-size: clamp(2vw, 5vw, 5rem);
    background-attachment: fixed;
    margin-bottom: 0rem;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
    }

    &::before {
        width: 100%;
        height: 100%;
        z-index: 0;    
    }
    &::after {
        background: rgba(255, 255, 255, .4);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);        
        width: 100vw;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        height: 100%;
        box-shadow: rgba(0, 0, 0, 0.01) 0px 19px 38px, rgba(0, 0, 0, 0.05) 0px 15px 12px;
        z-index: -1;
    }
}
.gearandparacontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    margin-bottom: 8rem;

}
.geardiv {
    position: absolute;
    background-image: url('../../assests/gear.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 50rem;
    height: 50rem;
    color: $mypurple;
    z-index: -2;
    animation: rotate 12s linear infinite;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    opacity: .6;
}
.geartwo {
    animation: rotatetwo 6s linear infinite;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    opacity: .6;
    margin-left: 2.8vw;
    width: 450px;
    height: 450px;
    position: absolute;
    float: right;
}
.geartwo:hover {
    animation: rotatetwo 6s linear infinite;
}
.gearscontainer {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
@keyframes rotatetwo {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(-359deg);
    }
}
.gearpone {
   font-size: 3rem;
   font-family: 'Rajdhani', sans-serif;
   font-weight: bold;
   color: $mypurple;
}
.gearptwo {
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Rajdhani', sans-serif;
    color: $mypurple;
}

    

.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  font-size: 1.5rem;
  border: none !important;
  outline: none !important;
  cursor: pointer;
  font-family: 'Rajdhani', sans-serif;
  position: relative;
  z-index: 0;
  border-radius: 10%;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background-color: $mypurple;
 
}
.button-85:hover  {
    background-color: rgba(94, 84, 142, .6);
    }




@media screen and (max-width: 1585px) {
.gearandparacontainer {    
    margin-bottom: 8rem;
}
.geardiv{
    width: 40rem;
    height: 40rem;
}
.gearpone {
    font-size: 2.5rem;
}
.gearptwo {
    font-size: 1.5rem;
}
.geartwo {
    animation: rotatetwo 6s linear infinite;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    opacity: .6;
    margin-left: 2vw;
    width: 350px;
    height: 350px;
    position: absolute;
    float: right;
}

}
@media screen and (max-width: 1100px) {
    .gearandparacontainer {    
        margin-bottom: 8rem;
    }

    .geardiv{
        width: 30rem;
        height: 30rem;
    }
    .gearpone {
        font-size: 2rem;
    }
    .gearptwo {
        font-size: 1rem;
    }
    .button-85 {
        width: 50%;
        height: 50%;
        font-size: .7rem;
    }
    .geartwo {
        animation: rotatetwo 6s linear infinite;
        filter: blur(4px);
        -webkit-filter: blur(4px);
        opacity: .6;
        margin-left: 2vw;
        width: 225px;
        height: 225px;
        position: absolute;
        float: right;
    }
}

@media screen and (max-width: 800px) {

    .gearandparacontainer {    
    
        margin-bottom: 4rem;
    
    }
    .geardiv{
        width: 22rem;
        height: 22rem;
    }
    .gearpone {
        font-size: 1.5rem;
    }
    .gearptwo {
        font-size: 1rem;
    }
    .button-85 {
        width: 50%;
        height: 50%;
        font-size: .5rem;
    }
    .geartwo {
        animation: rotatetwo 6s linear infinite;
        filter: blur(4px);
        -webkit-filter: blur(4px);
        opacity: .6;
        margin-left: 2vw;
        width: 150px;
        height: 150px;
        position: absolute;
        float: right;
    }
}
@media screen and (max-width: 630px) {

    .gearandparacontainer {    
    
        margin-bottom: 4rem;
    
    }
    .geardiv{
        width: 20rem;
        height: 20rem;
    }
    .gearpone {
        font-size: 1.5rem;
    }
    .gearptwo {
        font-size: 1rem;
    }
    .button-85 {
        width: 50%;
        height: 50%;
        font-size: .5rem;
    }
    .geartwo {
        animation: rotatetwo 6s linear infinite;
        filter: blur(4px);
        -webkit-filter: blur(4px);
        opacity: .6;
        margin-left: .5vw;
        width: 100px;
        height: 100px;
        position: absolute;
        float: right;
    }
}
@media screen and (max-width: 550px) {
    .gearandparacontainer {    
    
        margin-bottom: 4rem;
    
    }
    .geardiv{
        width: 15rem;
        height: 15rem;
    }
    .gearpone {
        font-size: 1.2rem;
    }
    .gearptwo {
        font-size: .8rem;
    }
    .button-85 {
        width: 50%;
        height: 50%;
        font-size: .5rem;
    }
    .geartwo {
        animation: rotatetwo 6s linear infinite;
        filter: blur(4px);
        -webkit-filter: blur(4px);
        opacity: .6;
        margin-left: -1.7vw;
        width: 100px;
        height: 100px;
        position: absolute;
        float: right;
    }
}

@media screen and (max-width: 320px) {
    .gearandparacontainer {    
    
        margin-bottom: 1rem;
    
    }
    .geardiv{
        width: 11rem;
        height: 11rem;
    }
    .gearpone {
        font-size: .8rem;
    }
    .gearptwo {
        font-size: .7rem;
    }
    .button-85 {
        width: 50%;
        height: 50%;
        font-size: .3rem;
    }
}


.svgparacarouselcontainer {
	background: rgba(255, 255, 255, .4);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);      color: $mypurple;
      font-family: 'Merienda', cursive;
      margin-top: 14rem;
      background-attachment: fixed;
        backdrop-filter: blur( 4px );
        -webkit-backdrop-filter: blur( 4px );

  
      &::before {
        z-index: 0;    
      }
      &::after {
        width: 100vw;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        height: 100%;
        z-index: -1;
      }
}

.svgandparacontainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 9rem !important;
    margin-left: 9rem !important;
    order: 0 !important;
    column-gap: 2rem;
    padding: 12rem;
    width: auto;
    height: auto;
}

.inner_poligon{
    order: 1 !important;
    display: flex;
    backdrop-filter: blur( 4px );
    width: 40rem; 
    min-width: 40rem;
    min-height: 40rem;
    height: 40rem; 
    justify-content: center;
    align-items: center;
    clip-path: circle(50% at 50% 50%); 
    max-width: 1440px;
    padding: 4vh;
    cursor: pointer;
}

.inner_poligon:active {
    will-change: transform;
    transition:  rotateY(45deg) 500ms ease-in-out;
    animation: rotateAnimation 2500ms infinite;
}

@keyframes rotateAnimation {
	from {transform: rotateY(45deg);}
	to {transform: rotateY(225deg);}
}

 @media screen and (max-width: 1000px) {
    .inner_poligon {
        flex-direction: column;
        width: 25rem; 
        min-width: 25rem;
        min-height: 25rem;
        height: 25rem;  
        min-inline-size: 10rem;
        padding: 9vh;
        order: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        clip-path: circle(50% at 50% 50%); 
    }
}


.bubblegumgif {
    width: auto;
    height: auto; 
    opacity: .8;
}

@media screen and (max-width: 1000px) {
    .bubblegumgif {
        display: inline-block;
        width: auto; 
        height: auto;
    }
}

@media screen
and (max-device-width: 0px) 
and (min-device-width: 320px) { 
    .inner_poligon {
        flex-direction: column;
        width: 10rem; 
        min-width: 10rem;
        height: 10rem;  
        min-height: 10rem;
        padding: 1vh;
        order: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        clip-path: circle(50% at 50% 50%); 
    }
    
.inner_poligon:active {
    animation: none !important;
}
.geardiv {
    animation: none !important;
}
.gearandparacontainer {
    visibility: hidden !important;
    height: 0 !important;
    width: 0 !important;
}
.geartwo {
    visibility: hidden !important;
    height: 0 !important;
    width: 0 !important;
}

.panelcontainer {
    visibility: hidden;
    height: 0;
    width: 0;
}
.gridcontainer {
    visibility: hidden;
    height: 0;
    width: 0;
}
    .bubblegumgif {
        min-height: 10rem;
        height: 10rem;
        width: 10rem;
        min-width: 10rem;
    }
    .svgandparacontainer {
        flex-direction: column;
        display: flex;
        order: 1 !important;
        column-gap: 2rem;
    }
    //  .imageoutercontainer {
    //     flex-flow: column;
    //     order: 2 !important;
    //     margin-bottom: 6rem;
    //     row-gap: 2rem;
    //     display: flex;        
    // }
    // .svgparacarouselcontainer {
    //     padding: 0 !important;
    //     margin-top: 2rem !important;
    // }
   
 
 }


@media screen and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .inner_poligon {
        flex-direction: column;
        width: 10rem; 
        min-width: 10rem;
        height: 10rem;  
        min-height: 10rem;
        padding: 1vh;
        order: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        clip-path: circle(50% at 50% 50%); 
    }
    .inner_poligon:active {
        animation: none !important;
    }
    .geardiv {
        animation: none !important;
    }
    .gearandparacontainer {
        visibility: hidden !important;
        height: 0 !important;
        width: 0 !important;
    }
    .geartwo {
        visibility: hidden !important;
        height: 0 !important;
        width: 0 !important;
    }
 
    .panelcontainer {
        visibility: hidden;
        height: 0;
        width: 0;
    }
    .gridcontainer {
        visibility: hidden;
        height: 0;
        width: 0;
    }
    .bubblegumgif {
        min-height: 10rem;
        min-width: 10rem;
    }
    .svgandparacontainer {
        flex-direction: column;
        display: flex;
        order: 1 !important;
        column-gap: 2rem;
     }
    
    //  .imageoutercontainer {
    //     flex-flow: column;
    //     order: 2 !important;
    //     margin-bottom: 4rem;
    //     row-gap: 2rem;
    //     display: flex;        
    // }
    // .svgparacarouselcontainer {
    //     padding: 0 !important;
    //     margin-top: 2rem !important;
    // }
  }

#aboutfirstparaid {
    font-size: calc(2.1vw + .5rem);
    order: 1 !important;
    width: 100%;
    height: 95%;
    max-width: 1440px;
    min-height: 40vh;
    padding: 1vh;
}


@media screen and (max-width: 1000px) {
    .svgandparacontainer {
        flex-direction: column;
        display: flex;
        padding-bottom: 0rem;
        order: 1 !important;
        column-gap: 2rem;
        justify-content: center;
        outline-offset: 3rem;
    }
    

    #aboutfirstparaid {
        min-height: 15vh;
        padding: 2vh;
        font-size: calc(2.1vw + .5rem);
        order: 1 !important;
        width: 100%;
        height: 95%;
        max-width: 1440px;
    }
    
}

.imageoutercontainer {
    order: 2 !important;
    min-height: 30vh;
    column-gap: 3rem;
    row-gap: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    scroll-behavior: smooth;
    color: rgba(255, 255, 255, .9);
    position: relative;
}



.imageoutercontainer img {
    max-width: max-content;
}
.imageoutercontainer {
    min-height: 30rem;
}
 .imageone__img {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $imageborderradius;
}
.imageone__img:hover {
    animation: none;
}
.imagetwo__img:hover {
    animation: none;
}
.imagethree__img:hover{
    animation: none;
}
.imagefour__img:hover {
    animation: none;
}
.imagefive__img:hover {
    animation: none;
}

.imagetwo__img {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $imageborderradius; 
}

.imagethree__img {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $imageborderradius; 
}

.imagefour__img {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $imageborderradius; 
}
.imagefive__img {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $imageborderradius;
}
.image {
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
}



@media screen and (min-width: 1490px) {
    
.imageoutercontainer {

    position: relative;




    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;      
    }

    &::before {
        width: 100%;
        height: 100%;
        z-index: -1;

    }
    &::after {
        width: 100vw;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: -2;
        height: 100%;
    }
}
}

@media screen 
and (min-width: 100px)
and (max-width: 1490px) {
    .imageoutercontainer {
        flex-direction: column;
        order: 2 !important;
        min-height: 30vh;
        margin-bottom: 6rem;
        column-gap: 3rem;
        display: flex;
        align-items: center;
    }
 
    .imageoutercontainer img {
        height: auto;
        position: relative;
        background-color:  rgba(94, 84, 142, 1);
    }
   
 
}
.image {
    width: auto !important;
    height: fit-content !important;
    position: relative !important;
    background: none;
    border: .1rem solid $mypurple !important;
    order: 2 !important;
    height: 25rem !important;
    padding: 1rem !important;
    border-radius: $imageborderradius !important;
    margin: 1rem !important;
}

.image__img {
    width: 100% !important;
    transition: opacity ease-out 250ms ;
    opacity: 1;
    border-radius: $imageborderradius;

}


.image__img:hover{
    opacity: 1;
    border-radius: $imageborderradius;
}

.image__overlay {
    position: absolute !important;
    top: 80% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    color: $mypurple;
    opacity: 1;
    transition: opacity ease-out 250ms;
    width: 100% !important;    
    height: 100% !important;
    display: flex !important;
    flex-flow: wrap !important;
    padding: 1rem !important;
    font-family: 'Rajdhani', sans-serif !important;
    font-weight: bold;
    font-size: 1.3rem;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: $imageborderradius;
}



.image:hover .image__overlay {
    opacity: 1;
    border-radius: $imageborderradius;
}



.imageoutercontainer img {
    width: 388px;
    height: 15rem;
    position: relative;
    background-color: rgba(0, 0, 0, .6);
}

.abouttitle {
    position: relative;
    font-family: 'Spline Sans Mono', monospace;
    width: max-content;
  }
  
 .abouttitle::before,
  .abouttitle::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .abouttitle::before {
    background: linear-gradient(60deg, #d9e1ef 0%, #d6e0ef 100%);
    animation: typewriter var(--typewriterSpeed)
      steps(var(--typewriterCharacters)) 1s forwards;
  }
  
  .abouttitle::after {
    width: 0.125em;
    background: $mypurple;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);   
    animation: typewriter var(--typewriterSpeed)
        steps(var(--typewriterCharacters)) 1s forwards,
      blink 750ms steps(var(--typewriterCharacters)) infinite;
  }
  
  
  @keyframes typewriter {
    to {
      left: 100%;
    }
  }
  
  @keyframes blink {
    to {
      background: transparent;
    }
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .imageoutercontainer {
    width: auto;
    height: auto;
    position: relative;
    background-attachment: fixed;
    margin-bottom: 0rem;

    &::before,
    &::after {
        content: '';
    }

    &::before {
        width: 100%;
        height: 100%;
        z-index: 0;    
    }
    &::after {
       
        width: 100vw;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        height: 100%;
        z-index: -1;
    }
}


.gridcontainer {
    display: grid;
    place-items: center;
    perspective: 15000px;
    width: 50%;
    perspective-origin: 50% calc(50% - 1300px);
    z-index: -1;
    opacity: .05;
}
.panelcontainer {
    display: flex;
    transform-style: preserve-3d;
    animation: rotater 24s infinite linear;

}
$width: 179px;
.panel {
    width: $width;
    height: 150px;
    position: absolute;
    overflow: hidden;
    // background-color: #fff4;
    transform: translate(-50%, -50%) rotateY(var(--angle)) translateZ(675px);
}
@keyframes rotater {
     to { transform: rotateY(-360deg);}
}
.panel::before {
    position: absolute;
    content: '"The only thing worse than starting something and failing... is not starting something."';
    font-size: 100px;
    left: var(--left);
    width: max-content;
    color: $mypurple;
}

@for $i from 0 to 24 {
    .panel:nth-child(#{$i+1}) {
        --left: #{$width * $i * -1};
        --angle: #{calc(360deg/ 24 * $i)};
    }
}





.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}



.carousel-content.show-1 > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}
.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    color: $mypurple;
    font-size: 35px;
    opacity: .9;
    background-color: rgba(255, 255, 255, .9);
    border: 1px solid $mypurple;
    cursor: pointer;
}

.left-arrow {
    left: 24px;
}

.right-arrow {
    right: 24px;
}
@media (hover: none) and (pointer: coarse) {
    .left-arrow, .right-arrow {
        display: none;
    }
}
.carousel-content.show-2 > * {
    width: 50%;
}

.carousel-content.show-3 > * {
    width: calc(100% / 3);
}

.carousel-content.show-4 > * {
    width: calc(100% / 4);
}


#background {
    position: relative;

}
