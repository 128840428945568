/* font-family: 'Merienda', cursive; */


// $rich form black: #080708;
// $champagne-pink: #f9dec9;
// $wild-blue-yonder: #99b2dd;
// $purple-navy: #5e548e;
// $paradise pink: #EF476F;

//variables
$myblack: rgba(8, 7, 8, 1);
$mypurple: rgba(94, 84, 142, 1);
$myblue: rgba(153, 178, 221, 1);
$mypink: rgba(249, 222, 201, 1);
$myred: rgba(238, 71, 111, 1);


.navcontainer{ 
    font-family: 'Rajdhani', sans-serif;
    background: rgba(255, 255, 255, .8);
    -webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
    cursor: pointer !important;
    font-size: clamp(.7rem ,2vw, 3rem);
    text-decoration: none;
}
ul {
    margin-block-end: 0;
    margin-block-start: 0;
    padding-inline-start: 0;
}
@media screen and (max-width: 1000px) {
    .navcontainer {
        outline: none;
    }
    .nav-list {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: none !important;
        a {
        text-decoration: none;
        }
    }
}
@media only screen 
and (max-device-width: 0px) 
and (min-device-width: 320px) { 
    
    .nav-list {
        margin-top: 0.2rem;
        width: 100%;
        min-width: none;
        column-gap: .9rem;
    }
    .navcontainer {
        outline: none;
        font-size: .4rem;
        height: fit-content;
        width: 100%;
    }

}
.nav-list {
    list-style-type: none;
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 320px !important;
    overflow: hidden;
    white-space: nowrap;
    column-gap: .9rem !important;
    margin-top: 0.2rem;
    a {
        color: $mypurple;
        text-decoration: none;
        border-bottom: 0.2rem solid $mypurple;
        border-top: 0.2rem solid $mypurple;
        padding: 1rem;
        transition: background 0.1s cubic-bezier(.33,.66,.66,1);
    }    
}

a {
    text-decoration: none;
    transition: background 0.1s cubic-bezier(.33,.66,.66,1);
}

a:hover,
a:focus {
	background: rgba(255, 255, 255, .9);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
    color: $mypurple;
    text-decoration: none;
}
.nav-list a:hover,
.nav-list a:focus {
    color: rgba(255, 255, 255, .9) !important;
    background: $mypurple !important;
}
#aboutbtnnav {
    order: 0 !important;
}
#projectsbtnnav {
    order: 1 !important;
} 
#skillsbtnnav {
    order: 2 !important;
}
#contactbtnnav {
    order: 3 !important;;
}
