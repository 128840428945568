
/* font-family: 'Merienda', cursive; */

// $rich form black: #080708;
// $champagne-pink: #f9dec9;
// $wild-blue-yonder: #99b2dd;
// $purple-navy: #5e548e;
// $paradise pink: #EF476F;



//variables
$myblack: rgba(8, 7, 8, 1);
$mypurple: rgba(94, 84, 142, 1);
$myblue: rgba(153, 178, 221, 1);
$mypink: rgba(249, 222, 201, 1);
$myred: rgba(238, 71, 111, 1);


.projectsouterdivclass {
    display: flex !important;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    height: auto;
    color: $mypurple;
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    
  }

 .projectsinnerdivclass {
  background: rgba(255, 255, 255, .4);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
  align-items: left;
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 5rem;
  background-attachment: fixed;
}
.videodiv {
  order: 1;
  width: 50%;
  margin: 1rem;
  margin: 1rem;
  z-index: -1;
}
.projectsparaclass {
  order: 1;
  width: 40%;

}
#projectstitleid {
  font-size: 6rem;
  order: 0;

}

@media screen and (max-width: 1000px) {
  #projectstitleid {
    font-size: 3rem;
  }
}
.projectsinnerdivclass{
    font-size: 2.5vh;
    width: 100%;
    padding: 5%;

}

.projectsmappingdivclass {
    display: flex;
    transform-style: flat;
    transition: transform 3s;
    width: 100%;
    max-width: 1440px;
    justify-content: center;
    align-items: center;
    font-size: 15pt;

    a {
      border-radius: 0%;
    }
  }
  .projectsmappingdivclass:hover, .projectsmappingdivclass:active, .projectsmappingdivclass:focus-within {
    border-radius: 0;
  }
  
  .projectimgclass img{
    width: 100%;
    padding: 5%;
    max-height: 40rem;
    aspect-ratio: auto;
  }
  .projectsimgcontainerclass {
    opacity: .95;
  }
  .projectsimgcontainerclass:hover, .projectsimgcontainerclass:active, .projectsimgcontainerclass:focus-within {
    opacity: 1;
  }   
  .projectimgclass {
    margin-top: 5%; 
    outline: .1rem solid $mypurple;
  }
  .projectsparaclass {
    padding: 0;
    margin: 0;
    width: 100%;
    
  }
 
@media screen and (max-width: 980px) {
  .projectimgclass {
    max-height: 30rem;
  }
}
@media screen and (max-width: 742px) {
  .videodiv {
  order: 1;
  width: 0;
  height: 0;
  margin: 1rem;
  padding: 1rem;
  visibility: hidden;
}
  
}
@media screen and (max-width: 1504px) {
    .projectsmappingdivclass {
        flex-direction: column;
        width: 100%;
        padding: 5%;
        font-size: 15pt;

    }
    .projectsouterdivclass {
        outline: none;
    }
}
@media only screen 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .projectsmappingdivclass {
      flex-direction: column;
      width: 100%;
      font-size: 1rem;
      padding: 5%;
    }
  .projectsouterdivclass {
      outline: none;
  }
  .projectsimgcontainerclass .projectimgclass{
    width: 250px;
}

  }

  @media only screen 
  and (max-device-width: 320px) { 
    .projectsmappingdivclass {
      flex-direction: column;
      width: 100%;
      padding: 5%;
      font-size: .7rem;
    }
  .projectsouterdivclass {
      outline: none;
  }
  .projectsimgcontainerclass .projectimgclass{
    width: 180px;
}
.videodiv {
  order: 1;
  width: 0;
  margin: 1rem;
  padding: 1rem;
  visibility: hidden;
}
  }
.projectslinkclass {
    margin: 1.5rem;
    width: 100%;
    height: auto;
    background: rgba(255, 255, 255, .4);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-attachment: fixed;
    border: none !important;
    color: $mypurple;
    align-items: center;
    justify-content: center;
}


.projectsimgcontainerclass .projectssubtitlecontainerclass {
    box-shadow: rgba(0, 0, 0, 0.01) 0px 19px 38px, rgba(0, 0, 0, 0.05) 0px 15px 12px;
    width: auto;
    padding: 4%;
    width: 100%;
    min-height: 30rem;


}

@-webkit-keyframes wobble-vertical-on-hover {
    16.65% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    33.3% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
    }
    49.95% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }
    66.6% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
    83.25% {
      -webkit-transform: translateY(1px);
      transform: translateY(1px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes wobble-vertical-on-hover {
    16.65% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    33.3% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
    }
    49.95% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }
    66.6% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
    83.25% {
      -webkit-transform: translateY(1px);
      transform: translateY(1px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  img {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
  }
  img:hover, img:focus, img:active {
    -webkit-animation-name: wobble-vertical-on-hover;
    animation-name: wobble-vertical-on-hover;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }


.svgProject {
  position: absolute;
  z-index: -1;
}
#github {
  color: $mypurple;
}

 .projectsmappingdivclass a:hover,
 .projectsmappingdivclass a:focus {
  background: $mypurple;
  color: rgba(255, 255, 255, 0.9);
 }