/* font-family: 'Merienda', cursive; */

// $rich form black: #080708;
// $champagne-pink: #f9dec9;
// $wild-blue-yonder: #99b2dd;
// $purple-navy: #5e548e;
// $paradise pink: #EF476F;

//my variables
$myblack: rgba(8, 7, 8, 1);
$mypurple: rgba(94, 84, 142, 1);
$myblue: rgba(153, 178, 221, 1);
$mypink: rgba(249, 222, 201, 1);
$myred: rgba(238, 71, 111, 1);


.contactoutercontainerclass {
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    color: $mypurple;
    background: rgba(255, 255, 255, .4);
    backdrop-filter: blur(10px);
    box-shadow: rgba(0, 0, 0, 0.01) 0px 19px 38px, rgba(0, 0, 0, 0.05) 0px 15px 12px;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-left: 5%;
    height: 100%;
    background-attachment: fixed;
}

.contactSvg {
    order: 1;
    min-width: 30%;
    min-height: 25rem;
    height: 30vw;
    margin: 1rem;
    margin-right: 2rem;
    background-image: url('../../assests/thankyou.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-position: center;
    opacity: .7;
    outline: .1rem solid $mypurple;
}

.formcontainer {
    order: 0;
    width: 55;
}
.contactformtitle {
    font-size: 3rem;
}
.paramessageclass {
    padding: 1rem;
}



.informationoutercontainerclass {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
    width: 100%;
    justify-items: center;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 1440px;
    transform-style: flat;

    

}
@media screen and (max-width: 1000px) {
    .informationoutercontainerclass {
        flex-direction: column;
        padding: 1vh;
        margin: 1vw;
    }
}
@media screen and (max-width: 910px) {
    .contactoutercontainerclass {
        flex-direction: column;
    }
    .contactSvg {
        order: 0;
        margin-top: 2rem;
        min-height: 0rem;
        height: 0rem;
        width: 0rem;
        min-width: 0rem;
    }
    .formcontainer {
        order: 1;
        width: 50;
        margin-right: 1rem;
    }
    
}




.contactform {
    flex-direction: column;
    order: 2;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-size: 130%;
    transition: all .4s ease;
    max-width: 1440px;
    transform-style: flat;
    margin: .6rem;
    

}
label {
    color:  rgba(255, 255, 255, .9);
    display: block;
    background: radial-gradient(circle, rgba(94,84,142,0.9303944315545244) 97%, rgba(94,84,142,0.46867749419953597) 100%);
    height: 3rem;
    width: 7rem;
    font-size: 1.3rem;    
    margin-right: .3rem;
    margin-bottom: .6rem;
    margin-top: 1rem;
    border-radius: 10%;
    padding-top: .6rem;
  }
  label:after { content: ": " }
  .inputcontainer {
      display: flex;
      justify-content: left;
      align-items: center; 
      
  }
  .nameandemailclass {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
  }
@media screen and (max-width: 1000px) {
    label {
    color:  rgba(255, 255, 255, .9);
    font-weight: bold;
    background: radial-gradient(circle, rgba(94,84,142,0.9303944315545244) 97%, rgba(94,84,142,0.46867749419953597) 100%);
    height: 30px;
    width: 80px;
    font-size: 1rem;
    margin-right: .3rem;
    margin-bottom: .3rem;
    align-items: left !important;
    padding-top: .1rem;
    border-radius: .5rem;

  }
  
}
@media screen and (max-width: 1000px) {
    textarea {
        min-height: 8rem;
        max-width: 15rem !important;
        min-width: 15rem !important;
        background: $mypink;
        color: $mypurple;
        font-size: 1rem;
    }
}
input[type=text]{
    height: 3rem;
    width: 15rem;
    }
input[type=email] {
    height: 3rem;
    width: 15rem;
    }
textarea[type=text] {   
    height: fit-content;
    max-width: 15rem;
    width: 15rem;
    min-height: 3rem;
    min-width: 15rem;
    }
@media screen and (max-width: 1000px) {
input[type=text]{
    height: 2.3rem;
    width: 15rem;
    }
input[type=email] {
    height: 2.3rem;
    width: 15rem;
    }
textarea[type=text]{
    height: fit-content;
    max-width: 15rem;
    min-width: 15rem;
    width: 15rem;
    }
    .nameandemailclass {
        flex-direction: column;
    }


  
}


.contactformtitle{
    order: 0;
}

.paramessageclass {
    order: 1;
}


.footer {
    order: 2;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}
 #linkedin {
     text-decoration: none !important;
     text-shadow: none !important;
     border-style: none !important;
     color: $mypurple;
 }
 #github {
     text-decoration: none !important;
     text-shadow: none !important;
     border-style: none !important;
 }


.inputcontainer {
    order: 2;
    border-radius: 1rem;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    min-width: 15rem;
    max-width: 1440px;
    transform-style: flat;
    display: flex;
    flex-direction: column;
    align-items: center;
}

textarea {
    min-height: 8rem;
    min-width: 31rem;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
    color: $mypurple;
    font-size: 1rem;
}

#email {
    background: none;
    color: $mypurple;
    -webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
    font-size: 1rem;
    border: .2rem solid $mypurple;
}
#name {
    background: none;
    -webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
    color: $mypurple;
    font-size: 1rem;
    border: .2rem solid $mypurple;
}
#message {
    background: none;
    color: $mypurple;
    font-size: 1rem;    
    border: .2rem solid $mypurple;
}

.submitbuttonclass {
    border-radius: .5rem;
    box-sizing: border-box;
    color: rgba(255, 255, 255, .9);
    cursor: pointer;
    padding: 1vw;
    max-width: 100%;
    min-width: 60%;
    order: 3;
    margin: 1vw;
    position: relative;
    border: 2px solid rgba(255, 255, 255, .9);
    background: none;
    z-index: 1;
    font-weight: bold;

    &:before {
        position: absolute;
        content: '';
        height: 100%;
        width: 100%;
        background: $mypurple;
        top: .5rem;
        left: .5rem;
        border-radius: .5rem;
        z-index: -1;
    }
}
.submitbuttonclass:hover, .submitbuttonclass:active {
    &:before {
        top: 0;
        left: 0;
    }
}
@media only screen 
  and (max-device-width: 320px) { 
    input[type=text]{
        height: 1.5rem;
        width: 10rem;
        }
        .inputcontainer {
            order: 2;
            border-radius: 1rem;
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            min-width: 15rem;
            max-width: 1440px;
            transform-style: flat;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    input[type=email] {
        height: 1.5rem;
        width: 10rem;
        }
  
        label {
            color:  rgba(255, 255, 255, .9);
            font-weight: bold;
            display: block;
            justify-items: center;
            justify-content: center;
            background: $mypurple;
            height: 20px;
            width: 80px;
            font-size: .8rem;
            margin-right: .3rem;
            margin-bottom: .5rem;
            margin-top: .4rem;
            border-radius: .25rem;
            padding-top: .1rem;
            padding-bottom: .1rem;
          }
            .contactform {
                padding: 1vh;
            }        
}

@media only screen and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    textarea {
        min-width: 15rem !important;
        max-width: 15rem !important;
        height: fit-content;
        min-height: 5rem;


  }
}
